<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col>
        <div class="login">

          <b-img :src="require('../assets/logo.png')"></b-img>

          <h4 class="title">Ingreso al Dashboard</h4>
          <form action class="form" @submit.prevent="login">


            <div class="mb-3 mt-3">
              <input
                  v-model="email"
                  class="form-control"
                  type="email"
                  id="email"
                  required
                  placeholder="Email"
              >
            </div>
            <div class="mb-3">
              <input
                  v-model="password"
                  class="form-control"
                  type="password"
                  id="password"
                  placeholder="Contraseña"
              >
            </div>
            <p v-if="error" class="error">Email o contraseña incorrectas</p>

            <div class="d-grid gap-2">
              <input class="btn btn-primary form-submit naranjaGramos" type="submit" value="Ingresar">
            </div>
          </form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import auth from "../logic/auth";
export default {
  data: () => ({
    email: "",
    password: "",
    error: false,
    accessToken:''
  }),
  methods: {
    async login() {
      try {
        const data_login = await auth.login(this.email, this.password);
        auth.setUserLogged(data_login.data.accessToken);
        this.$router.push("/dashboard")
      } catch (error) {
        this.error = true;
      }
    }
  },
  mounted() {
    this.accessToken = auth.getUserLogged();
    if (this.accessToken!='undefined' && typeof(this.accessToken)!='undefined'){
      this.$router.push('/dashboard')
    }
  }
};
</script>